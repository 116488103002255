import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './_components/login/login.component';
import { ErrorComponent } from './_components/error/error.component';
import {RegisterComponent} from './_components/register/register.component';
import { ForgetPasswordComponent } from './_components/forget-password/forget-password.component';
import { AbsherLoginComponent } from './_components/absher-login/absher-login.component';
import { AbsherLogoutComponent } from './_components/absher-logout/absher-logout.component';
import { AuthGuard } from './_helpers/auth-guard';
import { AbsherPostLogin } from './_helpers/absher-post-login';
import { FaqComponent } from './_components/faq/faq.component';
import { AbsherPostLogout } from './_helpers/absher-post-logout';
import { MainComponent } from './_components/main/main.component';
import { DntCaptchaComponent } from "./_components/dnt-captcha/dnt-captcha.component";
const routes: Routes = [
  { path: 'absherlogin', component: AbsherLoginComponent, canActivate: [AbsherPostLogin] },
  // { path: '', component: MainComponent},
  { path: '', component: LoginComponent},
  //{ path: '', component: AbsherLogoutComponent, canActivate: [AbsherPostLogout] },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent},
  { path: 'forgetPassword', component: ForgetPasswordComponent},
  { path: 'error', component: ErrorComponent },
  { path: 'dntCaptcha', component: DntCaptchaComponent},
  { path: 'FAQ', component: FaqComponent },
  { path: 'userprofile', loadChildren: () => import('./user-profile/user-profile.module').then(m => m.UserProfileModule) , canActivate: [AuthGuard]  },
  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
