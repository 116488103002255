import { Component, OnInit , Output , EventEmitter , Input } from '@angular/core';
import {getDaysInMonth , GregToIsl_MonthNo} from '../../../_helpers/hijri-helper';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-date-dropdown',
  templateUrl: './date-dropdown.component.html',
  styleUrls: ['./date-dropdown.component.css']
})
export class DateDropdownComponent implements OnInit {

  days: any[] = [];
  months: any[] = [];
  years: any[] = [];
  dateForm:FormGroup;
  _day;
  _month;
  _year;
  _fullDate: string;
  showDayDefault = false;
  showMonthDefault = false;
  showYearDefault = false;
  private _submitted = false;
  placeholder = "";
  dateRequired: boolean = true;
  noFutureDates: boolean = false;
  @Input()
  set fullDate(date){
    this.setDays(30);
    this._fullDate = date;
    if(this._fullDate == null || this._fullDate == ""){
      return;
    }
    this.year = this._fullDate.substring(0, 4);
    let month = this._fullDate.substring(4, 6);
    let day = this._fullDate.substring(6, 8);
    
  
    if(month.substring(0,1) == '0'){
      this.month = month.substring(1)
    }
    else{
      this.month = this._fullDate.substring(4, 6);
    }

    if(day.substring(0,1) == '0'){
      this.day = day.substring(1)
    }
    else{
      this.day = this._fullDate.substring(6, 8)
    }

  }

  @Input()
  set day(name) {
    setTimeout(()=>{
      this._day = name;
      this.dateForm.get('dayForm').setValue(name, {
        onlySelf: false
     });
    });
  
    
  }

  get day() { return this._day; }
 
  get month() { return this._month; }

  set month(name) {

    setTimeout(()=>{
      this._month = name ;
      this.dateForm.get('monthForm').setValue(name, {
        onlySelf: false
     });
    });
  }
 
  get year() { return this._year; }

  set year(name) {

   setTimeout(()=>{
    this._year = name ;
    this.dateForm.get('yearForm').setValue(name, {
      onlySelf: false
   });
  });
    
  }
 
  

  @Output() dateValue = new EventEmitter<any>();
  @Output() isValid = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder) {
    this.dateForm = this.formBuilder.group({
      dayForm: ['', [Validators.required, Validators.pattern("[0-9]+")]],
      monthForm: ['', [Validators.required, Validators.pattern("[0-9]+")]],
      yearForm: ['', [Validators.required, Validators.pattern("[0-9]+")]]
  });
   }

  ngOnInit() {
    this.initDates();

  }

  @Input()
  set submitted(name: boolean) {
    this._submitted = name ;
  }

  get submitted(): boolean { return this._submitted; }

  initDates(){
    var todaysDate = new Date();
    var date = GregToIsl_MonthNo(todaysDate.getDate(), todaysDate.getMonth() + 1, todaysDate.getFullYear())
    var toYear = date.getFullYear();
    /*var toDate = date.getDay();
    var toMonth = date.getMonth();*/
    this.years = [];
    this.years.push("سنة");
    this.months.push("شهر");

    for (var i = 1300 ;i <= toYear;i++){
      this.years.push(i)
    }
    

    for (var i = 1 ;i <= 12;i++){
      this.months.push(i);
    }
    this.setDays(30);
  }

  initDatesNoFuture() {
    this.noFutureDates = true;
    var todaysDate = new Date();
    var date = GregToIsl_MonthNo(todaysDate.getDate(), todaysDate.getMonth() + 1, todaysDate.getFullYear())
    var toYear = date.getFullYear();
    var toDay = date.getDate();
    var toMonth = date.getMonth();
    this.years = [];
    this.months = [];
    this.years.push("سنة");
    this.months.push("شهر");

    for (var i = 1300; i <= toYear; i++) {
      this.years.push(i)
    }



    for (var i = 1; i <= toMonth + 1; i++) {
      this.months.push(i);
    }
    this.setDays(toDay);
  }

  setDays(daysCount: number) {
    var todaysDate = new Date();
    var date = GregToIsl_MonthNo(todaysDate.getDate(), todaysDate.getMonth() + 1, todaysDate.getFullYear())
    var toDay = date.getDate();
    this.days = [];
    if (this.noFutureDates) {
      this.days.push("يوم");
      for (var i = 1; i <= toDay; i++) {
        this.days.push(i);
      }
    }
    else {
      this.days.push("يوم");
      for (var i = 1; i <= daysCount; i++) {
        this.days.push(i);
      }
    }
  }

  onYearSelect(e){
    this.showYearDefault = false;
    this.dateForm.get('yearForm').setValue(e.target.value, {
      onlySelf: false
   });

   
    let daysCount = getDaysInMonth(this.f.yearForm.value, this.f.monthForm.value);
    this.setDays(daysCount);
    this.sendFullDate();
    
    
  }

  onMonthSelect(e){
    this.showMonthDefault = false;
    this.dateForm.get('monthForm').setValue(e.target.value, {
      onlySelf: false
    });
    let daysCount = getDaysInMonth(this.f.yearForm.value, this.f.monthForm.value);
    this.setDays(daysCount);
    
    this.sendFullDate();
    
    
  }

 

  onDaySelect(e) {
    this.showDayDefault = false;
    this.dateForm.get('dayForm').setValue(e.target.value, {
      onlySelf: false
    });
    
   this.sendFullDate();

  }

  sendFullDate() {
    if (this.dateForm.invalid) {
      this.dateValue.emit(null);
      return;
    }
    let month = this.f.monthForm.value;
    if(this.f.monthForm.value < 10){
      month = "0" + this.f.monthForm.value;
    }
    let day = this.f.dayForm.value;
    if(this.f.dayForm.value < 10){
      day = "0" + this.f.dayForm.value
    }
    let year = this.f.yearForm.value
    let fullDate: string = year + month + day;

    this.dateValue.emit(fullDate);

  }

  reset() {
    
    this.day = null;
    this.month = null;
    this.year = null;
    this.dateForm.get('dayForm').setValue(this.day, {
      onlySelf: false
    });
    this.dateForm.get('monthForm').setValue(this.month, {
      onlySelf: false
    });
    this.dateForm.get('yearForm').setValue(this.year, {
      onlySelf: false
    });

    this.showDayDefault = true;
    this.showMonthDefault = true;
    this.showYearDefault = true;

  }


  get f() { return this.dateForm.controls; }



}
