import "rxjs/add/observable/throw";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";

import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { map, catchError } from 'rxjs/operators';
import { DNTCaptchaApiResponse, DNTCaptchaApiValue } from "./dnt-captcha-api-response";
import { CaptchaService } from "../../_services/dnt-captcha-services"
import CryptoJs from 'crypto-js';
import { Key } from "protractor";
import { observable } from "rxjs";
import { ok } from "assert";



@Component({
  selector: "dnt-captcha",
  templateUrl: "./dnt-captcha.component.html",
  styleUrls: ["./dnt-captcha.component.css"]
})
export class DntCaptchaComponent implements OnInit {
  isShown: string;
  ValidateLogin = false;
  MessageAlert: string;
  ApiResponse: DNTCaptchaApiResponse;
  @Input() inputText: string;
  @Output() captchaRefresh = new EventEmitter();


  constructor(private captchaService: CaptchaService) {
  }
  ngOnInit() {

    this.doRefresh(false);
  }

  doRefresh(clicked) {
    debugger;

    this.inputText = "";
    this.MessageAlert = "";
    this.isShown = null;
    this.captchaService.GetCaptcha().then(data => { this.ApiResponse = data; 
    });
    this.captchaRefresh.emit(clicked);

  }
  /*onInputTextFinshed() {
   debugger;
   this.isShown=this.inputText==""?null:this.inputText;
   if(this.isShown !=null)
   {
       var value=this.decryptData(this.apiResponse.Key,this.apiResponse.Value);
       if(value==this.inputText)
       {
         this.ValidateLogin=true;
         this.MessageAlert='';
         this.isShown="Wright";
       }
       else
       {
         this.MessageAlert="القيمة المدخلة خطأ الرجاء إعادة المحاولة";
         this.isShown="Wrong";
       }
   }
   else
   {
     this.MessageAlert='';
     this.isShown="Required";
   }
   return this.MessageAlert;
     //this.captchaStatus.emit(this.isShown);
  }*/

  async onCapchaFinshed() {
    debugger;
    this.isShown = this.inputText == "" ? null : this.inputText;
    if (this.isShown != null) {
      await this.captchaService.IsvalidCapch(this.inputText,this.ApiResponse.ServerValue).then(result => {
        if (result.valid === "True") {
          this.ValidateLogin = true;
          this.MessageAlert = '';
          this.isShown = "Wright";
        }
        else {
          this.MessageAlert = "القيمة المدخلة خطأ الرجاء إعادة المحاولة";
          this.isShown = "Wrong";
          this.ValidateLogin = false;
        }
      }).catch(err => {
        this.MessageAlert = err;
      });

    }
    else {
      this.MessageAlert = '';
      this.isShown = "Required";
      this.ValidateLogin = false;
    }
    return new DNTCaptchaApiValue(this.ValidateLogin, this.MessageAlert);

  }
}
