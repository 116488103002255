import { Component, OnInit , Output , EventEmitter} from '@angular/core';
import { NgbActiveModal , NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cancel-pop-up',
  templateUrl: './cancel-pop-up.component.html',
  styleUrls: ['./cancel-pop-up.component.css']
})
export class CancelPopUpComponent implements OnInit {
  message:String;
  @Output() cancelEvent = new EventEmitter<any>();
  @Output() redirectEvent =  new EventEmitter<any>();
  swapColors:Boolean = false;


  constructor(
    public activeModal: NgbActiveModal , config: NgbModalConfig
  ) {

    config.backdrop = 'static';
    config.keyboard = false;

   }

  ngOnInit() {
  }

  redirect(){
    this.redirectEvent.emit(true);
  }

}
