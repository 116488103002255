import { NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {DateDropdownComponent} from '../_shared-components/date-dropdown/date-dropdown.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { OtpComponent } from './otp/otp.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { CaptchaComponent } from './captcha/captcha.component';
import { EditPasswordComponent } from './edit-password/edit-password.component';
import { InfoModalComponent } from './info-modal/info-modal.component';
import { LogoComponent } from '../../_components/_shared-components/logo/logo.component';
import { CancelPopUpComponent } from './cancel-pop-up/cancel-pop-up.component';
import { DntCaptchaComponent } from "../../_components/dnt-captcha/dnt-captcha.component";

@NgModule({
  imports: [
    FormsModule ,
    ReactiveFormsModule,
    CommonModule,
    NgbModule
  ],
  declarations: [
    DateDropdownComponent,
    OtpComponent,
    CaptchaComponent,
    EditPasswordComponent,
    InfoModalComponent,
    LogoComponent,
    CancelPopUpComponent,
    DntCaptchaComponent
  ],
  exports: [
    DateDropdownComponent , OtpComponent , CaptchaComponent , EditPasswordComponent , InfoModalComponent , LogoComponent
    , CancelPopUpComponent,DntCaptchaComponent
  ],
  entryComponents:[OtpComponent , InfoModalComponent ,CancelPopUpComponent ]
})

export class SharedModule { }
