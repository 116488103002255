import { Component, OnInit  , ViewChild , Output , EventEmitter, Input} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../../_services/authentication.service';
import {NgbModal , NgbActiveModal , NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import {CaptchaComponent} from '../captcha/captcha.component';
import {responseEnum , errorMessage , otpResponse} from '../../ResponseEnum';
import {ForgetPasswordModel} from '../../../_models/ForgetPasswordModel';
import { CookieService } from 'ngx-cookie-service';
import Encrypt from '../../../_helpers/crypto';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.css'],
  providers: [NgbModalConfig, NgbModal]
})

export class OtpComponent implements OnInit {
  otpForm:FormGroup;
  submitted:boolean = false;
  loading:boolean = false;
  reSendLoading: boolean = false;
  isPassiveMode:boolean = false;
  nationalId:string;
  mobile:string;
  smsNotSent : boolean = false;
  forgetPassword : boolean = false;
  error = '';
  title = 'التحقق من رقم الجوال'
  @Output() otpResult = new EventEmitter<any>();
  _isCaptchaNeeded : boolean = false;
  @Output() otpError = new EventEmitter<any>();
  @Output() otpCancel = new EventEmitter<any>();
  @Output() otpSendSMS = new EventEmitter<any>();
  @Output() otpSMSError = new EventEmitter<any>();
  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService
    ,public activeModal: NgbActiveModal , config: NgbModalConfig ,
    private cookieService: CookieService
  ) {
    this.otpForm = this.formBuilder.group({
      OTP: ['' , Validators.required],
    
    });

    config.backdrop = 'static';
    config.keyboard = false;

   }

   @Input()
   set isCaptchaNeeded(name: boolean) {
     this._isCaptchaNeeded = false ;
       
      
   }
 
   get isCaptchaNeeded(): boolean { return this._isCaptchaNeeded; }

   get f() { return this.otpForm.controls; }

  async ngOnInit() {
    //this.cookieService.delete('id.fx');
  }
  async submit(){
    this.submitted = true;
    this.loading = true;
    if(this.otpForm.invalid){
      this.loading = false;
      return;
    }
    else{
      this.validateOTP();
    }
  }
  async validateOTP(){
    if(this.isPassiveMode){
      debugger
      this.otpResult.emit(this.f.OTP.value);
      this.loading = false;
    }
    else{
      let otpModel : ForgetPasswordModel = {
        NationalId : this.nationalId,
        Mobile: this.mobile,
        BirthDate: null,
        Code: this.f.OTP.value.toString()
      }
      await this.authenticationService.verifyPassCode(otpModel).then(data => {
        let isSuccess;
        let responseCode;
        //data = true;
        
        if(!(typeof data === 'string')){
          this.error = errorMessage;
          return
        }
        if(data[2] == '-'){
          responseCode = -5;
        }
        else{
          responseCode = parseInt(data[3]);
        }
        if(responseCode === otpResponse.SMSSent.code || responseCode === otpResponse.OTPVerified.code){
          isSuccess = true;
        }
        else if(responseCode === otpResponse.OTPVerificationFailed.code){
          isSuccess = false;
          this.error = otpResponse.OTPVerificationFailed.message;
        }
        else if(responseCode === otpResponse.OTPExpired.code){
          isSuccess = false;
          this.error = otpResponse.OTPExpired.message;
        }
        else if(responseCode === otpResponse.Error.code){
          isSuccess = false;
          this.error = errorMessage;
        }
        this.loading = false;
        this.otpResult.emit(isSuccess);
      }).catch(err =>{
        this.error = errorMessage;
        this.loading = false;
        this.otpError.emit(err);
      })
    }
    
    
  }

  async sendOTP(){
    debugger;
    this.setSecureCookie(this.mobile);
    await this.authenticationService.initiateMobileVerification(this.nationalId,this.mobile).then(data =>{
      let isSuccess;
      let errMessage;     
      if(data == otpResponse.SMSSent.code || data == otpResponse.OTPVerified.code){
        isSuccess = true;
      }
      else if(data == otpResponse.OTPVerificationFailed){
        isSuccess = false;
        errMessage = otpResponse.OTPVerificationFailed.message;
      }
      else if(data == otpResponse.OTPExpired){
        isSuccess = false;
        errMessage = otpResponse.OTPVerified.message;
      }
      else if(data == otpResponse.Error){
        isSuccess = false;
        errMessage = errorMessage;
      }
      this.otpSendSMS.emit({isSuccess: isSuccess , error: errMessage});
    }).catch(err =>{
      this.otpSMSError.emit(err);
    })
  }

  preventChars(evt) {
    ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault();
  }

  async reSendOtp(){
    this.reSendLoading = true;
    await this.sendOTP();
    this.reSendLoading = false;
  }

  setSecureCookie(mob){
    this.cookieService.delete('id.fx');
    var timeStamp = Date.now();
    let credentails: string = mob + String.fromCharCode(31) + timeStamp + String.fromCharCode(31);
    let cipher = Encrypt(credentails);
    this.cookieService.set('id.fx', cipher , 1 , "/");
  }

  cancel(){
    this.activeModal.close();
    this.otpCancel.emit(true);
  }

  

}
