import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';

@Injectable({ providedIn: 'root' })
export class AbsherPostLogin implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.authenticationService.postAbsherSignIn()//output: 'First Example'
      .then(result => {
        if (result == 2) {
          var returnUrl = sessionStorage.getItem('returnUrl');
          window.location.href = returnUrl;
        }
        else {
          sessionStorage.setItem('isUpdateNeeded', '1');
          this.router.navigate(['/userprofile']);
        }
        console.log('From Promise:', result);
      }).catch(err => {
        this.router.navigate(['/error']);
        //this.loading = false;
      });
    return false;
  }
}
