export var locale = {
  EnterNewPassLbl: "ادخال كلمة مرور جديدة",
  ChangePasslbl: "تَغيير كلمة المرور",
  Passlbl: "كلمة المرور",
  confimPassLbl: "تأكيد كلمة المرور",
  RequiredFieldMsg: "هذا الحقل مطلوب",
  Cancel: "الغاء العملية",
 SavePass: "ارسال",
  IncorrectPassPatternMsg: "كلمة المرور يجب ان لا تقل عن 6 خانات ويجب ان تحتوي على احرف انجليزية كبيرة وصغيرة وارقام",
  MustMatchNewPassMsg: 'تأكد من تطابق "كلمة المرور" و "تأكيد كلمة كلمة المرور"',


}
