

export var responseEnum = {
  InDB: { code: 4, message: "رقم الهوية مستخدم من قبل، يرجى تسجيل الدخول أو الضغط على نسيت كلمة المرور" },
  MobileFound: { code: 6, message: "رقم الجوال مسجل  من قبل" },
  UserExistInTempDB: { code: -6, message: "لقد تم حفظ البيانات من قبل وسيتم إرسال كلمة مرور عشوائية إلى رقم الجوال المسجل" },
  WrongNationalId: { code: -5, message: "رقم الهوية غير صحيح" },
  Success: { code: 6, message: "تمت العملية بنجاح" },
  UserNotAvail: { code: 7, message: "القيم المدخلة غير صحيحة" },
  UserNotAvail_ShowCaptcha: { code: 14, message: "القيم المدخلة غير صحيحة" }

}

export var locale = {
  ForgetPassTitle: "إستعادة كلمة المرور",
  SubForgetPassTitle:"الرجاء إستكمال الحقول للحصول على كلمة مرور جديدة",
  NationalId: "رقم الهوية / الاقامة",
  RequiredFieldMsg: "هذا الحقل مطلوب",
  IncorrectValue: "القيمة المدخلة غير صحيحة",
  DateBirth: "تاريخ الميلاد",
  Mobile: "رقم الجوال",
  ConfirmMobile: "تأكيد رقم الجوال",
  MissMatchPhoneNumber: 'عذرا، القيمة غير مطابقة لقيمة "رقم الجوال"',
  backButton: "رجوع",
  RetrivePassSubmit:"إستعادة",


}

export var otpResponse = {

  SMSSent: { code: 1, message: "" },
  UserBalanceConsumed: { code: 2, message: "" },
  UserHasBalance: { code: 3, message: "" },
  OTPVerified: { code: 4, message: "" },
  OTPExpired: { code: 6, message: "عذرا, قد انتهى الوقت المسموح لإدخال رمز التحقق. الرجاء الضغط على اعادة ارسال لإرسال رمز تحقق جديد" },
  OTPVerificationFailed: { code: 7, message: "البيانات المدخلة غير صحيحة. الرجاء التأكد" },
  Error: { code: -5, message: "" },

}

export var errorMessage = ' عذرا, حدث خطأ فني أثناء تنفيذ العملية. يرجى المحاولة في وقت لاحق';

