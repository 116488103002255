

export var responseEnum = {
  InDB: { code: 4, message: "رقم الهوية مسجل من قبل، يرجى تسجيل الدخول أو الضغط على نسيت كلمة المرور" },
  MobileFound: { code: 6, message: "رقم الجوال مسجل  من قبل" },
  UserExistInTempDB: { code: -6, message: "لقد تم حفظ البيانات من قبل وسيتم إرسال كلمة مرور عشوائية إلى رقم الجوال المسجل" },
  WrongNationalId: { code: -5, message: "رقم الهوية غير صحيح" },
  Success: { code: 7, message: "Success" },
  NotFound: { code: 0, message: "عذرا، لا يمكنك الاستمرار، البيانات المدخلة لا تتطابق مع سجلات الأحوال المدنية، يرجى مراجعتهم للتصحيح" },


}

export var otpResponse = {

  SMSSent: { code: 1, message: "" },
  UserBalanceConsumed: { code: 2, message: "" },
  UserHasBalance: { code: 3, message: "" },
  OTPVerified: { code: 4, message: "" },
  OTPExpired: { code: 6, message: "عذرا, قد انتهى الوقت المسموح لإدخال رمز التحقق. الرجاء الضغط على اعادة ارسال لإرسال رمز تحقق جديد" },
  OTPVerificationFailed: { code: 7, message: "البيانات المدخلة غير صحيحة. الرجاء التأكد" },
  Error: { code: -5, message: "" },

}

export var errorMessage = ' عذرا, حدث خطأ فني أثناء تنفيذ العملية. يرجى المحاولة في وقت لاحق';

//export default {responseEnum , errorMessage};

//-7

//عذراً، يوجد خطأ في الاتصال يُرجى المحاولة في وقت لاحق
