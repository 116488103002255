import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.css']
})
/** FAQ component*/
export class FaqComponent {
    /** FAQ ctor */
  constructor(private router: Router,) {

  }

  goBack() {
    this.router.navigate(['/login']);
  }
}
