export class DNTCaptchaApiResponse {
  constructor(
    public Image: string ,
    public Key:string,
    public Value:string ,
    public ClientValue: string ,
    public ServerValue: string,
    public Text: string,
    
  ) { }
}
export class DNTCaptchaApiValue
{
  constructor(
    public valid:boolean, 
    public Text:string
  ) { }
}
